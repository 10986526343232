import Link from "next/link";

export default function JoinWaitingList() {
  return (
    <>
      <div className="bg-slate-50 rounded py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-2">
        <div className="mx-auto max-w-screen-md sm:text-center">
          <h2 className="mb-4 text-3xl font-bold text-gray-900 sm:text-4xl">
            Start now.
          </h2>
          <p className="mx-auto mb-8 max-w-2xl font-normal text-gray-500 md:mb-12 sm:text-xl">
            Take on real-world Kubernetes challenges and prove your DevOps
            expertise. Sharpen your skills, solve complex scenarios, and elevate
            your Kubernetes game with FixTheOps.
          </p>
          <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0 flex justify-center">
            <Link
              href="/auth/signin"
              className="py-3 px-5 w-48 text-sm font-medium text-center text-white rounded-lg border cursor-pointer border-violet-600 sm:rounded-lg focus:ring-4 focus:ring-blue-300 bg-gradient-to-r from-blue-600 to-fuchsia-600 hover:from-blue-800 hover:to-violet-800 shadow-md hover:shadow-2xl"
            >
              Start Playing Now!
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
